<template>
  <div>
    <b-row class="mt-2 match-height">
      <b-col cols="9" xl="9" md="9">
        <b-row>
          <b-col xl="4" md="4" sm="6">
            <settings-card icon="PlusCircleIcon" statistic="Workshop" link="workshops" statistic-title="Workshop" color="info" class="white-color-text" style="background-color: #003B49; color: #FFFFFF !important;" />
          </b-col>
          <b-col xl="4" md="4" sm="6">
            <settings-card icon="UserIcon" statistic="My Profile" link="myProfile" statistic-title="My Profile" color="info" class="white-color-text" style="background-color: #003B49; color: #FFFFFF !important;" />
          </b-col>
          <b-col xl="4" md="4" sm="6">
            <settings-card icon="SettingsIcon" statistic="Leads" link="leads" statistic-title="Leads" color="info" class="white-color-text" style="background-color: #003B49; color: #FFFFFF !important;" />
          </b-col>
          <b-col xl="4" md="4" sm="6">
            <settings-card icon="SettingsIcon" statistic="Quotes" link="quotes" statistic-title="Quotes" color="info" class="white-color-text" style="background-color: #003B49; color: #FFFFFF !important;" />
          </b-col>
          <b-col xl="4" md="4" sm="6">
            <settings-card icon="SettingsIcon" statistic="Calendar" link="calendar" statistic-title="Calendar" color="info" class="white-color-text" style="background-color: #003B49; color: #FFFFFF !important;" />
          </b-col>
          <b-col xl="4" md="4" sm="6">
            <settings-card icon="SettingsIcon" statistic="Contracts" link="contracts" statistic-title="Contracts" color="info" class="white-color-text" style="background-color: #003B49; color: #FFFFFF !important;" />
          </b-col>
          <b-col xl="4" md="4" sm="6">
            <settings-card icon="SettingsIcon" statistic="Time & Effort" link="timeAndEfford" statistic-title="Time & Effort" color="info" class="white-color-text" style="background-color: #003B49; color: #FFFFFF !important;" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="4" xl="4" lg="4" md="6" sm="12">
            <chartjs-doughnut-chart />
          </b-col>
          <b-col cols="4" xl="4" lg="4" md="6" sm="12">
            <apex-radial-bar-chart />
          </b-col>
          <b-col cols="4" xl="4" lg="4" md="6" sm="12">
            <apex-donut-chart />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="3" xl="3" md="9">
        <b-card>
          <h4 class="text-center">Tasks</h4>
          <hr class="mb-2" />
          <hr class="mb-2" />
          <hr class="mb-2" />
          <hr class="mb-2" />
          <hr class="mb-2" />
          <hr class="mb-2" />
          <hr class="mb-2" />
          <hr class="mb-2" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination, BCardTitle, BCardText, BCard } from 'bootstrap-vue';

import store from '@/store';
import { avatarText } from '@core/utils/filter';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import SettingsCard from '@core/components/statistics-cards/SettingsCard.vue';
import ChartjsDoughnutChart from './Chart/ChartjsDoughnutChart.vue';
import ApexDonutChart from './Chart/ApexDonutChart.vue';
import ApexRadialBarChart from './Chart/ApexRadialBarChart.vue';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BTable,
    BFormSelect,
    BPagination,
    BCardTitle,
    BCardText,
    BCard,

    SettingsCard,
    ChartjsDoughnutChart,
    ApexDonutChart,
    ApexRadialBarChart,
  },

  methods: {},

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    return {
      // Filter
    };
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
  },

  data() {
    return {};
  },
};
</script>

<style>
.white-color-text h4 {
  color: #ffffff;
}
</style>
