<template>
  <b-card title="participation" style="max-height:350px;">
    <!-- chart -->
    <chartjs-component-doughnut-chart
      :height="250"
      :data="chartjsData.doughnutChart.data"
      :options="chartjsData.doughnutChart.options"
      class="mb-3"
    />
    <!--/ chart -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ChartjsComponentDoughnutChart from './charts-components/ChartjsComponentDoughnutChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    ChartjsComponentDoughnutChart,
    BCard,
  },
  data() {
    return {
      chartjsData,
    }
  },
}
</script>
